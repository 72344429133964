import Header from "./header/Header.js";
import Main from "./Main/Main.js";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import LandingPage from "./Pages/Landing.jsx";
import FrontendPage from "./Pages/Frontend.jsx";
import AdminLeads from "./Pages/AdminLeads.jsx";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={LandingPage}/>
          <Route path="/gs-leads" Component={AdminLeads}/>
          <Route path="/:frontend" Component={FrontendPage}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
