import Logo from "../images/geegstack-academy-logo.png"
import "./NavBar.css"
import { useEffect, useState } from "react";
import { Link , useParams} from "react-router-dom";

export default function NavBar () {
  // const navList = ["Download Package", "Other Courses"];
  const [toggleHambuger, setToggleHambuger] = useState(false); 
  const params = useParams();

    useEffect(() => {
console.log(params);
    })
  return (
    <>
      <nav className="nav">
        <div className="logo">
          <img src={Logo}
            className="logo-img"
            style={{width: "150px"}}>
          </img>
        </div>
        <ul className="nav-items">
          {/* {navList.map((ele, ind) => <li key={ind} */}
          {/* // className="nav-item"><a href="#">{ele}</a></li>)} */}
          <li className="nav-item"><Link to={"#"}>Download Package</Link></li>
          <li className="nav-item"><Link to={ params.frontend ? "/" : "/frontend"}>Other Courses</Link></li>
        </ul>
        { /* Hambugger */ }
        <div className={`hamburger ${toggleHambuger && "active"}`} 
          onClick={() => setToggleHambuger(toggleHambuger => !toggleHambuger)}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </nav>
      <ul className={`nav-items-mobile ${toggleHambuger && "active"}`}>
          {/* {navList.map((ele, ind) => <li key={ind}
          className="nav-item-mobile"><a href="#">{ele}</a></li>)} */}
          <li className="nav-item-mobile"><Link to={"#"}>Download Package</Link></li>
          <li className="nav-item-mobile"><Link to={ params.frontend ? "/" : "/frontend"}>Other Courses</Link></li>
      </ul>
    </>
  )
}

