import "./Form.css";
import { useRef, useState, useEffect } from "react";
import Toast from "../Toast";

export default function Form({customcss=false, course}) {
 
  const firstNameRef = useRef();
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
     e.preventDefault();
     setLoading(true);
     const form = {
      fname: firstName,
      lname: lastName,
      email,
      phone: phoneNumber,
      course: course + " development"
     }
     
     fetch("https://admissionserver.geegstack.academy/", {method: "POST", body: JSON.stringify(form), headers: {
      "Content-Type": "application/json"
     }})
     .then(res => res.json())
     .then(res => {
      setLoading(false)
      if (res.type === "error") {
        return Toast.fire({title: res.message, icon: "error", timer: 6000})
      }

      Toast.fire({title: "Course package has been sent to your email. Check the spam folder if you can't find it inbox.", icon: "success", timer: 10000});
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
   })
     .catch(err => {
        console.log("Returned error ", err);
        setLoading(false);
        Toast.fire({title: "Network error. Reload the page and retry.", icon: "error",  timer: 6000})
      
     })
  };


  
  useEffect(() => {
    firstNameRef.current.focus();
  }, []);
  
  return (
    <div className={`register ${customcss ? "custom" : ""}`}>
      <h3>Register to learn more about the program pricing and curriculum</h3>
      <form className="register_form" onSubmit={handleSubmit}>
        <div className="register_name input-wrapper">
          <div>
            <input
              type="text"
              placeholder="First Name"
              className="form-control"
              id="firstname"
              ref={firstNameRef}
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Last Name"
              className="form-control"
              id="lastname"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              required
            />
          </div>
        </div>
        <div className="input-wrapper">
          <input
            type="email"
            placeholder="Email Address"
            className="form-control"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </div>
        <div className="input-wrapper">
          <input
            type="tel"
            placeholder="Phone Number"
            className="form-control"
            id="phonenumber"
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            required
          />
        </div>
        <div className="submit">
          <button>{loading ? "Submitting form..." : "Get Program Package"}</button>
        </div>
      </form>
    </div>
  );
}
