
import { useEffect, useState } from "react";
import Toast from "../Toast";
import "./table.css";

const AdminLeads = () => {
    const [leads, setLeads] = useState([]);

    useEffect(() => {
        fetch("https://admissionserver.geegstack.academy/gs-leads")
        .then(res => res.json())
        .then(res => {
            setLeads(res.leads);
        })
        .catch(err => {
            Toast.fire({icon: "error", title: "Network error"})
        })
    }, [])
    return (

        <div className="App table-container">
            {
                leads.length > 0 ?
            <table>
                <tr>
                    <th>N/s</th>
                    <th>FIrst Name</th>
                    <th>Last Name</th>
                    <th>Course</th>
                    <th>Phone</th>
                    <th>Email</th>
                </tr>
                {
                    leads.map((lead, id) => {
                        return (
                            <tr key={id}>
                                <td>{id + 1}</td>
                                <td>{lead.fname}</td>
                                <td>{lead.lname}</td>
                                <td>{lead.course}</td>
                                <td>{lead.phone}</td>
                                <td>{lead.email}</td>
                            </tr>
                        )
                    })
                }
            </table>
            : 
            <h2>Loading leads...</h2>
            }
        </div>
    )
}

export default AdminLeads;