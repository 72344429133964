
import Main from "../Main/FullStack";
import Header from "../header/Header";

const LandingPage = () => {
    return (

        <div className="App">
            <Header />
            <Main />
        </div>
    )
}

export default LandingPage;