import "./CareerSupport.css";

export default function CareerSupport({track}) {
  return (
    <div className="geegstack-career-support">
      <h1>Career Support</h1>
      <p>
        By enrolling for our {track} development program, you will enjoy
        access to career tools and insights and connections to experts who are
        in the field.
      </p>
    </div>
  );
}
